
  /* -------------------------catégorie obligation------------------------ */

  .container_liste_form{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: start;
  
  }

  @media screen and (max-width: 390px){
    .container_liste_form{
        flex-direction:column;
        align-items: center;
       
    } }

  .first_container{
    width: 60%;
   border-radius: 10px;
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-top: 18px;

  }

  @media screen and (max-width: 390px){
    .first_container{
      width: 100%;
       
    } }
  .second_container{
    width: 39%;
    border-radius: 10px;
    background-color: white;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-top: 18px;
    
  }

  @media screen and (max-width: 390px){
    .second_container{
      width: 100%;
       
    } }
  .titre_cat{
    width: 100%;
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
    
  }
  .container_border{
    width: 100%;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
   border: 1px solid rgb(207, 205, 199);
   padding-top: 20px;
   padding-left: 20px;
   padding-right: 20px;
 
    
  }

  .container_form{
    width: 100%;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
  
   padding-bottom: 280px;
 
    
  }

  .sous_container_form{
    width: 100%;
    display: flex;
   flex-direction: row;
 justify-content: space-between;

    
  }
  .container_label{
    width: 30%;
    display: flex;
   flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    
  }
  .container_btn{
    width: 100%;
    display: flex;
   flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 15px 0;

    
  }

  /* -----------------data table -------------- */

  .container_header_data_table{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 390px){
    .container_header_data_table{
      flex-direction: column;
     align-items: center;
       
    } }

  .container_export{
   width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 390px){
    .container_export{
      width: 50%;
      margin-top: 20px;
       
    } }

    .status-valide{
      background-color: red;
    }

    .small-icon {
      font-size: 14px !important;
  }

  /* ------------------style table suivi----------- */
  
.container_info_suivi{
  width: 100%;
  display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 20px;
padding-left: 40px;
}
.libel_info_suivi{
font-weight: 600;
 font-size: 15px;
}
.body_info_suivi{
  font-size: 15px;
}
