.container_general {
  width: 220px;
  height: 100px;
  background-color: white;
  border-radius: 5px;
}
.container_contenu {
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 5px;
}
.texte_nbre_titre {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}
.titre_card {
  font-size: 0.8rem;
  font-weight: 700;
}
.nombre_elt {
  font-size: 1rem;
  font-weight: 500;
}
.bleu {
  border-bottom: 3px blue solid;
}
.jaune {
  border-bottom: 3px yellow solid;
}
.rouge {
  border-bottom: 3px #b3261eff solid;
}
.disposition_card_dash {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.titre_compatiment {
  font-size: 1rem;
  font-weight: 700;
}
.container_graphique {
  padding: 6px 0px;
}
.cardre_titre_ussd,
.cardre_titre_mnc,
.cardre_titre_ispc,
.cardre_titre_pnm {
  background-color: white;
  border-bottom: 2px #fafbffff solid;
  padding:6px 6px;
}
.graphe_ussd,
.graphe_mnc,
.graphe_ispc,
.graphe_pnm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
}

/* .graphe_mnc canvas {
  width: 90% !important;
  height: 220px !important;
} */
.graphe_ussd canvas {
    /* width: 90% !important; */
    height: 320px !important;
  }

.graphe_ispc canvas {
    /* width: 90% !important; */
    height: 320px !important;
  }
/* .graphe_pnm canvas {
    width: 2004px !important;
    height: 500px !important;
  } */
