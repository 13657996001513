/* -----------------steps-------------------------- */
.custom-steps {
    display: flex;
    justify-content: space-between;
  }
  
  
  
  .custom-steps .rs-steps-item-content {
    background-color:none; /* Couleur de fond des étapes */
    color:  #0d6efd; /* Couleur du texte des étapes */
  }
  
  .custom-steps .rs-steps-item-tail .rs-steps-item-tail::before {
    border-width: 5px !important;
    background-color: #C5C7CD !important; /* Couleur de la ligne de liaison entre les étapes */
  }
  
  .rs-steps-item-status-wait .rs-steps-item-title:after{
    border-color: #C5C7CD !important;
    background-color: #C5C7CD !important;
    border-top-width: 5px !important;
  }
  
  .rs-steps-item-status-process .rs-steps-item-title:after{
    border-color: #0d6efd !important;
    background-color: #0d6efd !important;
    border-top-width: 5px !important;
  }
  
  .rs-steps-item-status-finish .rs-steps-item-title:after{
    border-color: #0d6efd !important;
    background-color: #0d6efd !important;
    border-top-width: 5px !important;
  }
  
  
  /* cercles */
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper{
    border-color: #0d6efd !important;
    background-color: #0d6efd !important;
    color: #FFF;
  }
  
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper{
    border-color: #0d6efd !important;
    background-color: #0d6efd !important;
    color: #0d6efd !important;
  }
  
  .rs-steps-item-status-wait .rs-steps-item-icon-wrapper{
    border-color: #C5C7CD;
    background-color: #C5C7CD;
    color: #FFF;
  }
  
  
  .rs-steps-horizontal .rs-steps-item:not(:first-child){
    /* padding-left: 0; */
  }
  
  
  
  /* icons */
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper .rs-steps-item-icon .rs-steps-item-icon-wait{
    border-color: #C5C7CD;
    background-color: #C5C7CD;
    color: #C5C7CD;
  }
  
  
  
  
  .custom-steps .rs-steps-item-icon {
    color: #FFF; /* Couleur de l'icône des étapes */
  }
  
  
  
  
  
  /* Modal */
  .modal-body {
    padding: 24px !important;
  }
  
  
  /* PANEL */
  .rs-panel-body{
    padding: 0 !important;
  }
  .eDFgYj{
    padding: 24px 0px !important;
  }
  
  
  /* TOOGLE */
  .rs-toggle-checked .rs-toggle-presentation{
    background-color: var(--primary-color) !important;
  }
  
  
  
  
  /* selectlist */
  .rs-picker-menu{
    z-index: 10000 !important;
  }
  
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
   .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
   .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
    .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
     .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
   .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover{
    border-color: var(--primary-color) !important;
   }
  
   .rs-picker-default .rs-btn{
    height: 52px;
   }
  
  .rs-picker-toggle-active, .rs-picker.rs-picker-focused{
    box-shadow: none !important;
  }
  
  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 15px !important;
  }
  
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
   .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 15px !important;
  }
  
  /* multiple selectlist */
  .rs-picker-value-item{
    background-color: var(--primary-color) !important;
    color: #FFF !important;
    padding: 3px !important;
    border-radius: 4px !important;
  }
  
  .rs-picker-value-count{
    background-color: var(--primary-color) !important;
    color: #FFF !important;
  }
  
  .rs-picker-value-separator{
    color: var(--primary-color);
  }
  
  /* checkbox */
  .rs-checkbox-wrapper:before {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
  
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
   .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
  }
  
   .label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
      border-color: var(--primary-color) !important;
  }
  
  
  /* uploader */
  .rs-uploader-draggable .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover,
  .rs-uploader-draggable .rs-uploader-trigger-drag-over .rs-uploader-trigger-btn.rs-uploader-trigger-customize:hover{
      border: 1px solid var(--primary-color) !important;
  }
  
  
  
  
  @media screen and (max-width: 390px){
  
    .modal-body {
      padding: 16px !important;
    }
  
  
    .rs-picker-default .rs-picker-toggle.rs-btn {
      padding-top: 4px !important;
    }
  
    .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
   .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px !important;
  }
  }


  /* -------------------------catégorie plainte------------------------------ */

  .container_liste_form{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  
  }

  @media screen and (max-width: 390px){
    .container_liste_form{
        flex-direction:column;
        align-items: center;
       
    } }

  .first_container{
    width: 60%;
   border-radius: 10px;
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-top: 18px;

  }

  @media screen and (max-width: 390px){
    .first_container{
      width: 100%;
       
    } }
  .second_container{
    width: 39%;
    border-radius: 10px;
    background-color: white;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-top: 18px;
    
  }

  @media screen and (max-width: 390px){
    .second_container{
      width: 100%;
       
    } }
  .titre_cat{
    width: 100%;
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
    
  }
  .container_border{
    width: 100%;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
   border: 1px solid rgb(207, 205, 199);
   padding-top: 20px;
   padding-left: 20px;
   padding-right: 20px;
 
    
  }

  .container_form{
    width: 100%;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
  
   padding-bottom: 280px;
 
    
  }

  .sous_container_form{
    width: 100%;
    display: flex;
   flex-direction: row;
 justify-content: space-between;

    
  }
  .container_label{
    width: 30%;
    display: flex;
   flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    
  }
  .container_btn{
    width: 100%;
    display: flex;
   flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 15px 0;

    
  }

  /* -----------------data table -------------- */

  .container_header_data_table{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 390px){
    .container_header_data_table{
      flex-direction: column;
     align-items: center;
       
    } }

  .container_export{
   width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 390px){
    .container_export{
      width: 50%;
      margin-top: 20px;
       
    } }

    .status-valide{
      background-color: red;
    }

    .small-icon {
      font-size: 14px !important;
  }
  /* ----------------------------table détails ------------------------------ */
  .container_first_table{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

