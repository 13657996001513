
.container_liste_form{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
  }

  .second_container{
    width: 50%;
    border-radius: 10px;
    background-color: white;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-top: 18px;
    
  }
  .first_container{
    width: 50%;
    border-radius: 10px;
    background-color: white;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-top: 18px;
    
  }
.sous_container_form_profile{
    width: 100%;
    display:  flex;
    flex-direction: column;
    align-items: flex-start;
}
.container_form{
  width: 100%;
  display: flex;
 flex-direction: column;
 align-items: flex-start;

 padding-bottom: 280px;

  
}
.sous_container_profil{
    width: 100%;
   display: flex;
   flex-direction: row;
  justify-content: space-between;
}

